<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'videos' }">
          Video</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Add Video
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form @submit.prevent="submit" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            class="mt-2"
            :rules="rules.name"
            maxlength="255"
            v-model="form.name"
            :error-messages="form.$getError('name')"
          ></v-text-field>
          <v-alert v-if="form.$getError('file')" type="error" class="mt-5">
            {{ form.$getError('file') }}
          </v-alert>
          <div>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-success="uploadSuccess"
              @vdropzone-queue-complete="submitForm"
            ></vue-dropzone>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              color="deep-purple accent-4"
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <span class="red--text mt-4"
            >Do not leave this page while video upload is in progress</span
          >
          <v-divider class="my-5"></v-divider>

          <div class="mt-4">
            <v-btn type="submit" color="primary" :loading="form.$busy"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { each } from 'lodash'
import { mapActions } from 'vuex'
import { mdiChevronLeft } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Cookies from 'js-cookie'
import Form from '@/utils/form'
import vue2Dropzone from 'vue2-dropzone'

export default {
  components: {
    vueDropzone: vue2Dropzone,
    AppBarNavIcon,
  },
  data: function () {
    return {
      dropzoneOptions: {
        methods: 'PUT',
        url: process.env.VUE_APP_API_URL + '/admin/videos/upload',
        headers: {
          Authorization:
            'Bearer ' + Cookies.getJSON('AUTH_TOKEN')['access_token'],
        },
        video_id: this.$route.params.id,
        parallelUploads: 1,
        maxFilesize: 500000,
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        timeout: 600000,
      },
      icons: {
        arrowLeft: mdiChevronLeft,
      },
      rules: {
        name: [(v) => !!v || 'Name is required'],
      },
      form: new Form({
        name: '',
        file: [],
      }),
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      video_id: '',
      loading: false,
    }
  },

  methods: {
    ...mapActions({
      storeVideo: 'video/storeVideo',
    }),

    submitForm() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        formData.append(key, value)
      })

      this.storeVideo(formData)
        .then(() => {
          this.showSnackbar('Videos successfully created!', 'success')
          setTimeout(() => {
            this.$router.push({
              name: 'videos',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
          this.form.$busy = false
        })
    },

    submit() {
      this.loading = true
      if (this.$refs.myVueDropzone.getQueuedFiles().length) {
        this.$refs.myVueDropzone.processQueue()
      } else {
        this.submit()
      }
    },
    uploadSuccess(file, response) {
      this.loading = false
      this.form.file = file
    },
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },
}
</script>
